import React, { Component, Fragment } from "react";

class Privacy extends Component {
  render() {
    return (
      <Fragment>
        <div className="ptb--120 bg_color--1">
          <div className="container">
            <h2>Privacy Policy</h2>
            <p>Last updated: March 20, 2023</p>
            <p>
              Welcome to Itaccomplish.com. We understand that your privacy is
              important, and we are committed to protecting your personal
              information. This Privacy Policy outlines how we collect, use,
              store, and disclose your information when you use our website.
            </p>
            <h4>1. Information Collection and Use</h4>
            <p>
              We collect various types of information for different purposes,
              including:
            </p>
            <p>1.1. Personal Data</p>
            <p>
              When you use our website, we may ask you to provide us with
              certain personally identifiable information that can be used to
              contact or identify you. This may include, but is not limited to:
            </p>
            <p>Email address</p>
            <p>First name and last name</p>
            <p>Cookies and usage data</p>
            <p>1.2. Usage Data</p>
            <p>
              We may also collect information about how our website is accessed
              and used. This may include:
            </p>
            <p>Browser type and version</p>
            <p>Operating system</p>
            <p>IP address</p>
            <p>Pages visited on our website</p>
            <p>Time and date of your visit</p>
            <p>Time spent on those pages</p>
            <p>1.3. Tracking & Cookies Data</p>
            <p>
              We use cookies and similar tracking technologies to track activity
              on our website and hold certain information. You can instruct your
              browser to refuse all cookies or to indicate when a cookie is
              being sent. However, if you do not accept cookies, you may not be
              able to use some portions of our website.
            </p>
            <h4>2. Use of Data</h4>
            <p>
              Itaccomplish.com uses the collected data for various purposes:
            </p>
            <p>To provide and maintain our website</p>
            <p>To improve and personalize user experience</p>
            <p>
              To communicate with you about changes, updates, or promotional
              materials
            </p>
            <p>
              To monitor usage and detect, prevent, and address technical issues
            </p>
            <p>To comply with legal obligations</p>
            <h4>3. Data Retention</h4>
            <p>
              We will retain your personal information only for as long as
              necessary to fulfill the purposes for which it was collected,
              including for the purposes of satisfying any legal, accounting, or
              reporting requirements.
            </p>
            <h4>4. Disclosure of Data</h4>
            <p>
              We may disclose your personal information in the good faith belief
              that such action is necessary to:
            </p>
            <p>Comply with a legal obligation</p>
            <p>Protect and defend our rights or property</p>
            <p>
              Prevent or investigate possible wrongdoing in connection with our
              website
            </p>
            <p>Protect the personal safety of users or the public</p>
            <p>Protect against legal liability</p>
            <h4>5. Security of Data</h4>
            <p>
              The security of your data is important to us, but remember that no
              method of transmission over the internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your personal data, we cannot
              guarantee its absolute security.
            </p>
            <h4>6. Changes to This Privacy Policy</h4>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              You are advised to review this Privacy Policy periodically for any
              changes.
            </p>
            <h4>7. Contact Us</h4>
            <p>
              If you have any questions or concerns about this Privacy Policy,
              please contact us:
            </p>
            <p>By email: tgb29@itaccomplish.com</p>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Privacy;
